import React, { Component } from 'react';
import axios from 'axios';

const API_PATH = 'https://www.wombournelimo.co.uk/sendemail.php';

class Contact extends Component {
    constructor(props){
        super(props);
        this.state = {
            enquiryname : '',
            enquiryemail : '',
            enquirytelephone : '',
            enquirymessage : '',
            mailSent: false,
            error: null
        }
    
        //this.props.userLogout(this.props.userDetail);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    
      }

      resetForm = () => {
        this.setState({mailSent: false});
        document.getElementById('enquiryname').value = '';
        document.getElementById('enquiryemail').value = '';
        document.getElementById('enquirytelephone').value = '';
        document.getElementById('enquirymessage').value = '';
       
    }


      onSubmitForm = e => {
        e.preventDefault();
        axios({
          method: "post",
          url: `${API_PATH}`,
          headers: { "content-type": "application/json" },
          data: this.state
        })
        .then (
            this.setState({
                        mailSent: 'true'
                      })
        );
                    }

    onenquirynameChange = (event) => {
        this.setState({enquiryname: event.target.value});
      }
    onenquiryemailChange = (event) => {
        this.setState({enquiryemail: event.target.value});
      }
    onenquirytelephoneChange = (event) => {
        this.setState({enquirytelephone: event.target.value});
      }
    onmessageChange = (event) => {
        this.setState({enquirymessage: event.target.value});
      }
    

    render(){
        return(
            <div className="Contact">
                <h1>Contact Form</h1>
                   <div className="measure">
                   <form id="main-contact-form" className="contact-form" name="contact-form" method="post" action="sendemail.php">
                        <fieldset id="sign_up" >
                            <legend className="title">Please call 01902 892635 or complete this form and we will contact you to discuss your requirements.</legend>
                            <div className="inputText col-sm-6">
                                {/* <label htmlFor="enquiryname">Name</label> */}
                                <input 
                                    className="inputBox" 
                                    type="text" 
                                    name="enquiryname"   
                                    id="enquiryname" 
                                    placeholder="Please enter your name"
                                    onChange={this.onenquirynameChange}
                                    />
                            </div>
                             <div className="inputText col-sm-6" >
                                {/* <label htmlFor="enquiryemail">Email</label> */}
                                <input 
                                    className="inputBox" 
                                    type="text" 
                                    name="enquiryemail"  
                                    id="enquiryemail" 
                                    placeholder="Please enter your email address"
                                    onChange={this.onenquiryemailChange}
                                    />
                            </div>
                            <div className="inputText col-sm-6">
                                {/* <label htmlFor="enquirytelephone">Telephone</label> */}
                                <input 
                                    className="inputBox" 
                                    type="text" 
                                    name="enquirytelephone"  
                                    id="enquirytelephone" 
                                    placeholder="Please enter your contact number"
                                    onChange={this.onenquirytelephoneChange}
                                    />
                           </div>
                            <div className="inputText blankpanel col-sm-6">
                            </div>
                            <div className="inputMsg col-sm-12">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    className="inputArea"  
                                    name="enquirymessage"  
                                    id="enquirymessage" 
                                    placeholder="Please enter your message here"
                                    onChange={this.onmessageChange}
                                    />
                            </div>
                        </fieldset>
                        <div className="form-group sendBtn">
                            <input type="submit" onClick={e => this.onSubmitForm(e)} className="btn btn-danger btn-lg" value="Send Message"/>
                        </div>
                        <div>
                            {/* {console.log('Error',this.state.error)}
                            {console.log('MailSent',this.state.mailSent)} */}
                            {this.state.mailSent && <div className="success">Thank you for contacting us. <button onClick={this.resetForm} type="button">Close</button></div>}
                            {this.state.error && <div className="error">MESSAGE NOT SENT</div>}
                        </div>
                    </form>
                </div>
            </div>
        );
    }};


export default  Contact;