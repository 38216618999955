import React, { Component } from 'react';



class Footer extends Component {


    getYear() {
        return new Date().getFullYear();
    }

    render(){
    return(
        <div id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-4">
                       &copy;{this.getYear()} Wombourne Funeral Services
                    </div>
                    <div className="col-sm-4">
                       &nbsp;
                    </div>
                    <div className="col-sm-4">
                        Developed by<a target="_blank" rel="noopener noreferrer" href="http://www.wildgoat.co.uk/terms.pdf" title="Wildgoat.co.uk">Wild Goat Web Design</a>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default Footer;