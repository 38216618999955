import React, { Component } from 'react';
import Scrollspy from 'react-scrollspy'


class Menu extends Component {  
    render(){
        return(
        <section id='menu'>
            <nav className="menuPanel">
                <Scrollspy items={ ['header', 'about','services','gallery','contact'] } 
                           currentClassName="is-current"
                           >  
                        <button onClick={() => this.props.menuScroll('header')}>Home</button>
                        <button onClick={() => this.props.menuScroll('about')}>About</button>
                        <button onClick={() => this.props.menuScroll('services')}>Services</button>
                        <button onClick={() => this.props.menuScroll('gallery')}>Gallery</button>
                        <button onClick={() => this.props.menuScroll('contact')}>Contact</button>
                </Scrollspy>
            </nav>
        </section>
        );
    }
}

export default Menu;