import React, { Component } from 'react';

class About extends Component {

    render(){
        return(
        <div className="txtPanel">
            <h1>About</h1>    
                <h3>Please call 01902 892635</h3>
                <h2>Wombourne Limousine Hire for the funeral trade</h2>
                <p>We hire chauffeur driven hearse and limousines to funeral directors organising funerals, we can cover the whole of the Black Country, Shropshire, Worcestershire and Staffordshire.</p>
                <p>Wombourne Limousine Hire is a friendly, family company based in the Midlands, we pride ourselves on delivering quality chauffeur driven transportation at a realistic and competitive cost without cutting quality.</p>
                <p>We run a Rolls Royce hearse, a Norwood hearse, two Rosedale limousines and a Dorchester limousine.</p>
                <p>The Wombourne Limousine Car hire will allow you to get your clients to their destination without worry. We have a team of professional and experienced chauffeurs who will make sure that everything goes smoothly during the funeral service.</p> 
                <p>All our funerals cars are kept to the highest standard, in their appearance and mechanically. Our limousine services provides the ultimate in sophicated respectful transportation.</p>
                <p>Please do call us so we can give you the best possible price.</p>
                <p>We at Wombourne Limousine Hire will always put our customers first.</p>
        </div>
        );
    }
}

export default About;
