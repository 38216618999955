import React, { Component } from 'react';


class Services extends Component {

    render(){
        return(
        <div className="txtPanel">
            <h1>Wombourne Limousine services</h1>
            <p>We pride ourselves on supporting funeral directors with the freedom and flexibility the funeral industry needs.</p>
            <ul>
                <li>Quality Hearses and Limousines to the funeral sector</li>     
                <li>Professional and friendly fully suited chauffeur complete with cap.</li>
                <li>Covering Black Country, Shropshire, Worcestershire and Staffordshire</li>
                <li>Full funeral fleets</li> 
                <li>Single vehicles</li>
                <li>Tailor solutions to your funeral transport needs</li>
                <li>Provide a specialist cortege</li>
                <li>Arrangement and display of floral tributes</li>
                <li>Devise the best route</li>
                <li>The best way of trying to keep the cortege together, dependent on route</li>
                <li>Bearers available</li>
                <li>Conductor available</li>
            </ul> 
            <p>Please do call us so we can give you the best possible price.</p>
        </div>
        );
    }
}

export default Services;