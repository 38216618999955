import React, { Component } from 'react';
import GalleryList from "./GalleryList";

class Gallery extends Component {
    

    render(){
        return(
            <div className="galleryList">
                <h1>Gallery</h1>
                <p>&nbsp;</p>
                <GalleryList />
            </div>
        );
    }};


export default  Gallery;