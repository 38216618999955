import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'


class MobileMenu extends Component {
    constructor(props){
        super(props);
        this.state= {
          showMobile: false
        }
        this.showMobileMenu = this.showMobileMenu.bind(this);
      }

    showMobileMenu() {
        this.setState({showMobile: !this.state.showMobile});
    }

    onScroll(option){
        setTimeout(() => {
            this.setState({showMobile: !this.state.showMobile})
        }, 1500);
        this.props.menuScroll(option);

    }
    
    render(){

        return(
        <section id='mobilePanel'>
                <button id="hamBurger" onClick={this.showMobileMenu}>
                {this.state.showMobile
                    ?<FontAwesomeIcon icon={faTimes} size="1x" />
                    :<FontAwesomeIcon icon={faBars} size="1x" />
                }   
                </button>
            <nav className="mobileMenuPanel">
                {this.state.showMobile
                    ? <div className="mobileMenu">
                            <button onClick={() => this.onScroll('header')}>Home</button>
                            <button onClick={() => this.onScroll('about')}>About</button>
                            <button onClick={() => this.onScroll('services')}>Services</button>
                            <button onClick={() => this.onScroll('gallery')}>Gallery</button>
                            <button onClick={() => this.onScroll('contact')}>Contact</button>
                    </div>
                    :''
                }
            </nav>
        </section>
        );
    }
}

export default MobileMenu;