import React, { Component } from 'react';
import './App.css';

import About from './sections/About';
import Services from './sections/Services';
import Gallery from './sections/Gallery';
import Contact from './sections/Contact';
import Footer from './sections/Footer';
import Menu from './sections/Menu';
import MobileMenu from './sections/MobileMenu';


class App extends Component {
  constructor(props){
    super(props);
    this.state = {} 
    this.menuScroll = this.menuScroll.bind(this); 
    
}

menuScroll (option) {
  var elmnt = document.getElementById(option);
  elmnt.scrollIntoView({behavior: 'smooth', block: 'start' });}

  render() {


    return (
    <div className="App">
      <div id="mobileMenu">
          <MobileMenu
          menuScroll = {this.menuScroll} />
      </div>
      <div id="header" className="App">
        <header className="App-header">
            <div className="headerText">
              <h1>Wombourne Limousine Hire</h1>
              <p>Funeral trade only limousine hire based in the Midlands, we pride ourselves on delivering quality chauffeur driven transportation.</p>
            </div>
        </header>
      </div>
      <div id="topMenu">
          <Menu 
          menuScroll = {this.menuScroll} />
        </div>
      <div id="about">
        <About />
      </div>

      <div id="services">
        <Services />
      </div>      
      <div id="gallery">
        <Gallery />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
    );
  }
}

export default App;
