import React from 'react';

import ModalImage from "react-modal-image";

import l1sm from '../images/i20sm.jpg';
import l2sm from '../images/l2sm.jpg';
import l3sm from '../images/l3sm.jpg';
import l4sm from '../images/i21sm.jpg';
import l5sm from '../images/i24sm.jpg';
import l6sm from '../images/i22sm.jpg';
import l7sm from '../images/i14sm.jpg';
import l8sm from '../images/i23sm.jpg';
import l9sm from '../images/i16sm.jpg';

import l1 from '../images/i20.jpg';
import l2 from '../images/l2.jpg';
import l3 from '../images/l3.jpg';
import l4 from '../images/i21.jpg';
import l5 from '../images/i24.jpg';
import l6 from '../images/i22.jpg';
import l7 from '../images/i14.jpg';
import l8 from '../images/i23.jpg';
import l9 from '../images/i16.jpg';


export default () => (
            <div className="galleryItems">
                <div className="col-sm-4">                   
                    <ModalImage small={l1sm} large={l1} alt="Wombourne & Kinver Memorial" />
                </div>
                <div className="col-sm-4">
                    <ModalImage small={l2sm} large={l2} alt="Wombourne & Kinver Memorial" />
                </div>
                <div className="col-sm-4">
                    <ModalImage small={l3sm} large={l3} alt="Wombourne & Kinver Memorial" />
                </div>
                <div className="col-sm-4">
                    <ModalImage small={l4sm} large={l4} alt="Wombourne & Kinver Memorial" />
                </div>
                <div className="col-sm-4">
                    <ModalImage small={l5sm} large={l5} alt="Wombourne & Kinver Memorial" />
                </div>
                <div className="col-sm-4">
                    <ModalImage small={l6sm} large={l6} alt="Wombourne & Kinver Memorial" />
                </div>
                <div className="col-sm-4">
                    <ModalImage small={l7sm} large={l7} alt="Wombourne & Kinver Memorial" />
                </div>
                <div className="col-sm-4">
                    <ModalImage small={l8sm} large={l8} alt="Wombourne & Kinver Memorial" />
                </div>
                <div className="col-sm-4">
                    <ModalImage small={l9sm} large={l9} alt="Wombourne & Kinver Memorial" />
                </div>
            </div>
        );